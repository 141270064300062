<template>
    <div class="filter-container" :class="{'open': filterOpen}">
        <div class="filter-header">
            <div class="filter-header-title">筛选条件</div>
        </div>
        <div class="filter-body">
            <div v-if="filterChecked && filterChecked.length" class="filter-item filter-checked">
                <div class="item-label">已经选条件</div>
                <div class="item-value">
                    <el-tag v-for="item in filterChecked" :key="item.name" size="small" class="filter-tag" closable @close="handleToClose(item)">{{ item.label }}</el-tag>
                </div>
                <div class="item-btn">
                    <el-button type="text" icon="el-icon-delete" class="button-filter-clear" @click="handleToClear">全部清除</el-button>
                </div>
            </div>

            <template v-for="(item, index) in filterData">
                <div v-if="!item.hidden && item.data.length" :key="item.name" class="filter-item">
                    <div class="item-label">{{ item.label }}</div>
                    <div class="item-value">
                        <el-button v-for="(citem, cindex) in item.data" :key="item.name + index + cindex" type="text" @click="handleToCheck(item, citem)">{{ citem.label }}</el-button>
                    </div>
                </div>
            </template>
        </div>

        <div class="filter-footer">
            <el-button type="text" @click="handleToOpen"><i class="el-icon-d-arrow-right" :class="{'open': filterOpen}"></i></el-button>
        </div>
    </div>
</template>

<script>
import { getCitys, getIndustry, getStatic } from '/src/utils/staticData';

export default {
    name: 'homeIndexFilter',    
    data() {
        return {
            citysLoading: false,
            industryLoading: false,

            staticData: getStatic(),
            citysData: [],
            industryData: [],

            filterOpen: false,
            filterChecked: [],
            filterData: [
                {
                    name: 'provinceCode',
                    label: '省份地区',
                    childrenKey: 'cityCode',
                    hidden: false,
                    data: [],
                },
                {
                    name: 'cityCode',
                    label: '城市区县',
                    hidden: true,
                    parentKey: 'provinceCode',
                    data: [],
                },
                {
                    name: 'industryCode',
                    label: '服务行业',
                    childrenKey: 'secondIndustryCode',
                    hidden: false,
                    data: [],
                },
                {
                    name: 'secondIndustryCode',
                    label: '服务行业',
                    hidden: true,
                    parentKey: 'industryCode',
                    data: [],
                },
                {
                    name: 'estiblishTime',
                    label: '成立时间',
                    hidden: true,
                    openShow: true,
                    data: [],
                },
                {
                    name: 'regCapital',
                    label: '注册资本',
                    hidden: true,
                    openShow: true,
                    data: [],
                },
                {
                    name: 'companyType',
                    label: '企业类型',
                    hidden: true,
                    openShow: true,
                    data: [],
                },
                {
                    name: 'phoneStatus',
                    label: '联系方式',
                    hidden: true,
                    openShow: true,
                    data: [],
                },
                {
                    name: 'emailStatus',
                    label: '邮箱地址',
                    hidden: true,
                    openShow: true,
                    data: [],
                },
                {
                    name: 'followStatus',
                    label: '跟进状态',
                    hidden: true,
                    openShow: true,
                    data: [],
                },
                {
                    name: 'collectionStatus',
                    label: '是否收藏',
                    hidden: true,
                    openShow: true,
                    data: [],
                },
            ],
        };
    },
    computed: {
        userInfo () {
            return this.$store.getters.userInfo || {};
        },
        vipFlag () {
            return this.$store.getters.vipFlag || false;
        },
    },
    created() {
        this.handleStaticDataInit();
    },
    mounted() {
        this.handleFilterInit();
    },
    methods: {
        // 静态数据初始化
        handleStaticDataInit() {
            const { staticData, filterData } = this;

            filterData.forEach((item) => {
                if (staticData[item.name]) {
                    item.data = staticData[item.name];
                }
            });
            
            this.handleGetCitys();
            this.handleGetIndustry();
        },

        // 筛选数据处理
        handleFilterInit(tpye) {
            const { filterOpen, filterChecked } = this;
            // 已筛选的数据
            const checkedKey = filterChecked.map(item => item.name);
            // 传递的筛选值
            const tmpFilterData = {};

            this.filterData.forEach((item) => {
                // 筛选了已选条件之后，该筛选项消失，不再展示
                item.hidden = checkedKey.indexOf(item.name) >= 0;

                // 受父级控制
                if (item.parentKey && !item.hidden) {
                    // 父级已选
                    item.hidden = checkedKey.indexOf(item.parentKey) < 0;
                }
                
                if (item.openShow && !filterOpen) {
                    // 受展开收起控制
                    item.hidden = true;
                }

                // 设置默认空值，保证每个字段都有传
                tmpFilterData[item.name] = '';

                // console.log(item.name, item.hidden);
            });
            
            filterChecked.forEach(item => {
                tmpFilterData[item.name] = item.value;
            });

            // console.log(tmpFilterData);

            if (tpye === 'open') {
                // 展开收齐时，不发起请求
                return false;
            }

            this.$emit('change', tmpFilterData);
        },

        // 展开收起
        handleToOpen() {
            const { filterOpen } = this;

            this.filterOpen = !filterOpen;

            this.handleFilterInit('open');
        },

        // 选中选项
        handleToCheck({ name, childrenKey }, item) {            
            const { userInfo, vipFlag, citysData, industryData } = this;


            // console.log(name, ['followStatus', 'collectionStatus'].indexOf(name));

            // 跟进及收藏条件需判断是否登录
            if (['followStatus', 'collectionStatus'].indexOf(name) >= 0 && (!userInfo.weiXinUnionId || !vipFlag)) {
                this.$emit('change', 'login');

                return false;
            }
            
            // 增加选项
            this.filterChecked.push({
                name,
                childrenKey,
                ...item,
            });

            // console.log(item);

            // 获取子项的值
            if (name === 'provinceCode') {
                const tmpData = citysData.filter(citem => citem.code === item.value);

                console.log(tmpData);

                const tmpCitys = tmpData.length && tmpData[0].citys ? tmpData[0].citys.map((titem) => {
                    return {
                        label: titem.name,
                        value: titem.code
                    };
                }) : [];

                this.handleSetFilterData('cityCode', tmpCitys);
            } else if (name === 'industryCode') {
                const tmpData = industryData.filter(citem => citem.code === item.value);
                const tmpIndustry = tmpData.length && tmpData[0].children ? tmpData[0].children.map((titem) => {
                    return {
                        label: titem.name,
                        value: titem.code
                    };
                }) : [];

                this.handleSetFilterData('secondIndustryCode', tmpIndustry);
            }

            this.handleFilterInit();
        },

        // 取消选项
        handleToClose(item) {
            const { filterChecked } = this;

            // console.log(item);

            this.filterChecked = filterChecked.filter(citem => citem.name !== item.name && citem.name !== item.childrenKey);

            this.handleFilterInit();
        },

        // 清空
        handleToClear() {
            this.filterChecked = [];

            this.handleFilterInit();
        },

        // 设置筛选数据
        handleSetFilterData(key, data) {
            const { filterData } = this;

            filterData.forEach((item) => {
                if (item.name === key) {
                    item.data = data;
                }
            });
        },

        // 获取省份城市
        handleGetCitys() {
            if (this.citysLoading) {
                return false;
            }

            this.citysLoading = true;

            getCitys((data) => {
                this.citysLoading = false;

                if (data) {
                    this.citysData = data;

                    const tmpData = data.map((citem) => {
                        return {
                            label: citem.name,
                            value: citem.code
                        };
                    });

                    this.handleSetFilterData('provinceCode', tmpData);
                }
            });
        },

        // 获取行业
        handleGetIndustry() {
            if (this.industryLoading) {
                return false;
            }

            this.industryLoading = true;

            getIndustry((data) => {
                this.industryLoading = false;

                if (data) {
                    this.industryData = data;

                    const tmpData = data.map((citem) => {
                        return {
                            label: citem.name,
                            value: citem.code
                        };
                    });

                    this.handleSetFilterData('industryCode', tmpData);
                }
            });
        },
    },
}
</script>

<style lang="scss" scoped>
@import "./HomeFilter";
</style>
