<template>
    <div class="home-container">
        <div class="banner">
            <el-image
                src="/images/banner.jpg"
                fit="cover"
                class="banner-image"
                alt="banner"
            >
            </el-image>

            <div class="wrap">
                <div class="keywords" :class="keywordsClass">
                    <div class="keywords-title">客户资源</div>
                    <div class="keywords-input">
                        <el-input
                            v-model="listQuery.keyword"
                            placeholder="输入企业名称/简介等关键词"
                            class="el-input__keywords"
                            clearable
                            @focus="handleKeywordsFocus"
                            @blur="handleKeywordsBlur"
                            @change="handleKeywordsChange"
                            @keyup.enter.native="handleKeywordsEnter"
                        >
                            <i slot="suffix" class="el-input__icon el-icon-search keyword-icon-search" @click="handleKeywordsEnter"></i>
                        </el-input>
                    </div>
                    <div class="keywords-tips">
                        <p>成为VIP，尊享搜索，更加高效精准连接潜在客户</p>
                        <el-button class="button-to-buy" @click="handleToBuy">立即开通</el-button>
                    </div>
                </div>

                <div class="slogan">
                    <div class="slogan-item">
                        <div class="slogan-pic"><el-image src="/images/slogan-ico.jpg" alt="图标"></el-image></div>
                        <div class="slogan-text">全国全行业客户资源<br>精准锁定客户线索</div>
                    </div>
                    <div class="slogan-item">
                        <div class="slogan-pic"><el-image src="/images/slogan-ico-02.jpg" alt="图标"></el-image></div>
                        <div class="slogan-text">海量资源每日更新<br>智能挖掘优质客户</div>
                    </div>
                    <div class="slogan-item">
                        <div class="slogan-pic"><el-image src="/images/slogan-ico-03.jpg" alt="图标"></el-image></div>
                        <div class="slogan-text">按标签智能推荐高匹配客户<br>一手商机快速获取</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-loading.fullscreen.lock="listLoading" class="list">
            <div class="wrap">
                <div class="list-filter">
                    <HomeFilter ref="HomeFilter" @change="handleFilterChange" />
                </div>

                <div class="list-table">
                    <HomeTable ref="HomeTable" :listQuery="listQuery" @change="handleTableChange" />
                </div>
            </div>
        </div>

        <ExportCompany ref="ExportCompany" :queryParam="listQuery" @confirm="handlePayConfirm" />
        <CompanyDetail ref="CompanyDetail" :detailData="companyData" @change="handleDetailChange" />        
        <OrderPay ref="OrderPay" @success="handlePaySuccess" />
        <Login ref="Login" @success="handleLoginSuccess" />
        <BuyVip ref="BuyVip" @confirm="handlePayConfirm" />
        <NeedCompany ref="NeedCompany" />
    </div>
</template>

<script>
import ExportCompany from '/src/components/Dialog/exportCompany.vue';
import CompanyDetail from '/src/components/Dialog/companyDetail.vue';
import NeedCompany from '/src/components/Dialog/needCompany.vue';

import HomeFilter from './components/HomeFilter.vue';
import HomeTable from './components/HomeTable.vue';

import commonMixins from '/src/mixins/commonMixins';

import { track } from '/src/directive/track/sendData';
import { deepClone } from '/src/utils/index';

import { ApiCompanyFollow } from '/src/api/search';

export default {
    name: 'homeIndexPage',
    components: {
        ExportCompany,
        CompanyDetail,
        NeedCompany,
        HomeFilter,
        HomeTable,
    },
    mixins: [commonMixins],
    data() {
        const tmpKeyword = this.$route.query?.keyword || '';

        return {
            sending: false,

            keywordsClass: '',

            listLoading: false,            
            listQuery: {
                timestamp: 0,
                keyword: tmpKeyword,
                cityCode: '',
                collectionStatus: '',
                emailStatus: '',
                estiblishTime: '',
                followStatus: '',
                industryCode: '',
                secondIndustryCode: '',
                offset: 0,
                // pageNum: 0,
                // pageSize: 0,
                phoneStatus: '',
                provinceCode: '',
                regCapital: '',
                companyType: '',
            },

            // 当前客户数据
            companyData: {},
        };
    },
    watch: {
        vipFlag() {
            // if (newVal.guid && !oldVal.guid) {
            //     // 登录
            //     this.handleToSearch();
            // } else if (!newVal.guid && oldVal.guid) {
            //     // 注销
            //     this.handleToSearch();
            // }
            this.handleToSearch();
        },
    },
    // computed: {},
    // mounted() {
    // },
    methods: {
        handleToBuy() {
            this.handleIsLoginAndVip();
        },

        handleKeywordsFocus() {
            const { userInfo, vipFlag } = this;

            this.keywordsClass = userInfo.weiXinUnionId && vipFlag ? '' : 'open';
        },

        handleKeywordsBlur() {
            setTimeout(() => {
                // 延迟处理
                this.keywordsClass = '';
            }, 300);
        },

        handleKeywordsChange() {
            // console.log('change');
        },

        handleKeywordsEnter() {            
            if (this.handleIsLoginAndVip()) {
                // 搜索操作
                this.handleToSearch();
            }
        },

        // 筛选操作
        handleFilterChange(data) {
            const { listQuery } = this;

            // console.log(data);

            if (data === 'login' && !this.handleIsLoginAndVip()) {
                // 受控字段，未登录且不是vip
                return false;
            }

            // console.log({
            //     ...listQuery,
            //     ...data,
            // });

            // 筛选条件改变，自动发起请求
            this.listQuery = {
                ...listQuery,
                ...data,
            };

            this.handleToSearch();
        },

        // 列表搜索
        handleToSearch() {
            if (this.listLoading) {
                return false;
            }

            this.listLoading = true;

            this.$nextTick(() => {
                this.$refs.HomeTable.handleGetList();
            });
        },
        
        // 客户偏好设置
        handleNeedCompanyOpen() {
            if (this.handleIsLoginAndVip(true)) {
                // 设置
                this.$refs.NeedCompany.handleOpen();
            }
        },

        // 列表操作
        handleTableChange(parames) {
            const { type, data } = parames;

            // console.log(parames);

            switch (type) {
                case 'ready':
                    this.listLoading = false;

                    break;
                case 'link':
                    if (['companyName', 'companyNameHighlight'].indexOf(data.item.prop) >= 0) {
                        this.companyData = data.row;

                        if (this.handleIsLoginAndVip(true)) {
                            // 打开客户详情 - 登录后才允许查看详情
                            this.$refs.CompanyDetail.handleOpen();
                        }

                        // 上报
                        track('t:click_homepage_companydetails;id:' + data.row.companyId);
                    }

                    break;                
                case 'button':
                    const tmpName = data.bitem.name;

                    if (['phones', 'followStatus', 'collectionStatus'].indexOf(tmpName) >= 0) {
                        if (this.handleIsLoginAndVip()) {
                            // 展开详情
                            this.companyData = data.row;

                            const tmpKeys = {
                                followStatus: 'follow',
                                collectionStatus: 'collection',
                            };

                            if (tmpKeys[tmpName]) {
                                this.handleDetailChange({
                                    type: tmpKeys[tmpName]
                                });
                            }
                        } else {
                            const trackKeys = {
                                phones: 'click_homepage_unlocklandline',
                                followStatus: 'click_homepage_companystate',
                                collectionStatus: 'click_homepage_companyoperation',
                            };

                            // 上报
                            trackKeys[tmpName] && track('t:'+ trackKeys[tmpName] +';id:' + data.row.companyId);
                        }
                    }

                    break;
                case 'vip':
                    if (!this.handleIsLoginAndVip()) {
                        // 上报 - 列表上的vip
                        // track('t:');
                    }

                    break;
                case 'need':
                    this.handleNeedCompanyOpen();

                    break;
                case 'export':
                    if (this.handleIsLoginAndVip(true)) {
                        // 导出
                        this.$refs.ExportCompany.handleOpen(data.total);
                    }
                    
                    break;
            
                default:
                    // if (!vipFlag) {
                    //     this.$refs.BuyVip.handleOpen();
                    // }
                    break;
            }
        },

        // 客户详情操作
        handleDetailChange(parames) {
            const { companyData } = this;
            const { type } = parames;

            switch (type) {
                case 'need':
                    this.handleNeedCompanyOpen();

                    break;
                case 'follow':
                    this.handleSetInfo(0, companyData.followStatus === 0 ? 1 : 0);

                    break;
                case 'collection':
                    this.handleSetInfo(1, companyData.collectionStatus === 0 ? 1 : 0);

                    break;
                case 'vip':
                    this.handleToBuy();

                    break;
                case 'login':
                    this.$refs.Login.handleOpen();

                    break;
                default:
                    break;
            }
        },

        // 跟进收藏操作
        handleSetInfo(dealType, value) {
            const { companyData, staticData } = this;
            const tmpData = deepClone(companyData);

            if (this.sending) {
                return false;
            }

            if (!this.handleIsLoginAndVip()) {
                return false;
            }

            this.sending = true;

            if (dealType === 0) {
                tmpData.followStatus = value;
            } else if (dealType === 1) {
                tmpData.collectionStatus = value;
            }

            // console.log(tmpQuery);

            ApiCompanyFollow({
                companyId: tmpData.companyId,
                // 处理类型 0：跟进 1：收藏
                dealType,
            }).then((res) => {
                this.sending = false;

                if (dealType === 0) {
                    companyData.followStatus = value;
                    companyData.followLabel = staticData.follow[value];
                } else if (dealType === 1) {
                    companyData.collectionStatus = value;
                    companyData.collectionLabel = staticData.collection[value];
                }
            }).catch(() => {
                this.sending = false;
            });
        },
    },
}
</script>

<style lang="scss" scoped>
@import "./index";
</style>