<template>
    <el-dialog
        :title="detailData.companyName"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        custom-class="el-dialog--full"
        width="900px"
    >
        <div class="detail-main">
            <div class="detail-content">
                <div class="detail-info base-info">
                    <div class="info-header">
                        <span>企业信息</span>

                        <el-button type="text" class="info-header-btn" @click="handleToNeed">找不到我要的客户？告诉锐眼 >></el-button>
                    </div>
                    <div class="info-main">
                        <div v-for="item in detailCfg" :key="item.name" class="info-item">
                            <div class="info-item-label">{{ item.label }}：</div>
                            <div class="info-item-centent">{{ handleContentFormat(item) }}</div>
                        </div>
                    </div>
                </div>

                <div class="detail-info more-info">
                    <div class="info-header">
                        <span>企业关键人</span>
                    </div>
                    <div class="info-main">
                        <div class="info-item">
                            <div class="info-item-label">姓名：</div>
                            <div class="info-item-centent">{{ detailData.legalPersonName }}</div>
                        </div>
                        <div class="info-item">
                            <div class="info-item-label">职位：</div>
                            <div class="info-item-centent">{{ detailData.position }}</div>
                        </div>
                    </div>
                </div>

                <div class="detail-info more-info">
                    <div class="info-header">
                        <span>企业联系方式</span>
                    </div>
                    <div class="info-main">
                        <div class="info-item">
                            <div class="info-item-label" style="width: 78px;">手机/座机：</div>
                            <div class="info-item-centent">
                                <span v-if="vipFlag">{{ detailData.phones }}</span>
                                <el-button v-else type="text" @click="handleToBuyVip">点击解锁</el-button>
                            </div>
                        </div>
                        <div class="info-item">
                            <div class="info-item-label">邮箱：</div>
                            <div class="info-item-centent">
                                <span v-if="vipFlag">{{ detailData.emails }}</span>
                                <el-button v-else type="text" @click="handleToBuyVip">点击解锁</el-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="detail-tips">注:该数据源于公开网络，无版权争议</div>
            </div>
            <div class="detail-aside">
                <div class="detail-ctrl">                    
                    <div class="info-header">操作</div>
                    <div class="info-main">                        
                        <!-- <el-dropdown @command="handleFollowCommand">
                            <el-button v-loading="sending" type="primary" size="small">{{ detailData.followLabel }}</el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="1">跟进</el-dropdown-item>
                                <el-dropdown-item command="2">无意向</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown> -->

                        <el-button v-loading="sending" type="primary" size="small" @click="handleToFollow">{{ detailData.followLabel }}</el-button>

                        <el-button v-loading="sending" size="small" class="collection-button" @click="handleToCollection">{{ detailData.collectionLabel }}</el-button>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
// import { ApiCompanyFollow } from '/src/api/search';

// import { deepClone } from '/src/utils/index';
import { getStatic } from '/src/utils/staticData';

export default {
    name: "CcompanyDetail",
    props: {
        detailData: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            dialogVisible: false,
            sending: false,

            staticData: getStatic(),

            detailCfg: [
                {
                    name: 'companyName',
                    label: '客户名称',
                },
                {
                    // 省份城市拼接
                    name: 'provinceCity',
                    label: '所属省市',
                },
                {
                    name: 'industry',
                    label: '行业类型',
                },
                {
                    name: 'regCapital',
                    label: '注册资金',
                },
                {
                    name: 'companyType',
                    label: '企业类型',
                },
                {
                    name: 'estiblishTime',
                    label: '注册时间',
                },
                {
                    name: 'regLocation',
                    label: '公司地址',
                },
                {
                    name: 'businessScope',
                    label: '经营范围',
                },
            ],
        };
    },
    computed: {
        userInfo () {
            return this.$store.getters.userInfo || {};
        },
        vipFlag () {
            return this.$store.getters.vipFlag || false;
        },
    },
    // created() {},
    mounted() {
        // this.handleOpen();
    },
    methods: {
        handleOpen() {
            this.dialogVisible = true;
        },

        handleContentFormat(item) {
            const { detailData } = this;
            let tmpValue = detailData[item.name];

            if (item.name === 'regCapital') {
                tmpValue += '万元';
            }

            return tmpValue;
        },

        // 设置客户搜索偏好
        handleToNeed() {
            this.$emit('change', {
                type: 'need',
            });
        },

        // 跟进
        handleFollowCommand(command) {
            const { detailData } = this;
            const tmpValue = parseInt(command);
            
            if (detailData.followStatus === tmpValue) {
                return false;
            }
            
            this.handleSetInfo(0, tmpValue);
        },

        // 跟进
        handleToFollow() {
            this.$emit('change', {
                type: 'follow',
            });
        },

        // 收藏
        handleToCollection() {
            this.$emit('change', {
                type: 'collection',
            });
        },

        // 购买vip
        handleToBuyVip() {            
            const { detailData } = this;
            
            // 上报
            track('t:click_companydetails_unlock;id:' + detailData.companyId);

            this.$emit('change', {
                type: 'vip',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.detail-main {
    display: flex;
    min-height: 300px;    
}

.detail-content,
.detail-aside {
    box-sizing: border-box;
    height: 100%;
}

.detail-content {
    padding: 40px;
    padding-bottom: 15px;
    flex: 1;
    border-right: 1px solid #D2D2D2;
}

.detail-aside {
    padding: 40px 0 0 23px;
    width: 242px;
}

.info-header {
    position: relative;
    margin-bottom: 10px;

    line-height: 30px;
    font-size: 18px;
    color: #535353;

    &-btn {
        position: absolute;
        top: 0;
        right: 0;

        padding: 7px 0;
    }
}

.detail-info {
    + .detail-info {
        margin-top: 20px;
    }
}

.info-item {
    display: flex;
    width: 100%;
    min-height: 30px;

    font-size: 14px;
    line-height: 30px;
    color: #535353;

    &-label {
        width: 72px;
        margin-right: 5px;
    }

    &-centent {
        flex: 1;

        padding: 3px 5px 3px 0;

        line-height: 24px;
    }

    .el-button--text {
        padding: 0;
    }
}

.more-info {
    .info-main {
        display: flex;
    }

    .info-item {
        width: 50%;

        &-label {
            width: 45px;
        }
    }
}

.detail-tips {
    margin-top: 50px;

    font-size: 12px;
    text-align: right;
}

.detail-ctrl {
    .el-button {
        display: block;
        width: 180px;
        padding: 11px 15px;
        border-radius: 20px;

        font-size: 16px;

        + .el-button {
            margin: 20px 0 0 0;
        }
    }
}
</style>
